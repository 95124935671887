
export function PoetryProps(date, poetry) {
    let returnObject = Object.create({date: date, poetry: poetry})
    returnObject.date = date
    returnObject.poetry = poetry
    return returnObject
}

export function MasterListOfPoetry() {
    const list = []
    list.push(
        PoetryProps("9/6/24", "Praise the Omnissiah."),
        PoetryProps("9/5/24", "Good luck and have fun."),
        PoetryProps("9/3/24", "Never deprive anyone of hope, it may be all they have."),
        PoetryProps("9/2/24", "Never waste an opportunity to tell someone you love them."),
        PoetryProps("9/2/24", "Saying what's on your mind will never lose you someone who is supposed to be in your life.  ~ Emily Wood"),
        PoetryProps("8/27/24", "Just do good things and good things will happen to you mate."),
        PoetryProps("8/27/24", "So how do I do it, how do I turn my dreams into reality?"),
        PoetryProps("8/27/24", "What is the first thing anyone does before they start their day? You Wake Up,"),
        PoetryProps("8/25/24", "He may have gone too far, but deep down I believe he is a good man."),
        PoetryProps("8/20/24", "No matter who you blame for your problems, they are still your problems to solve."),
        PoetryProps("7/30/24", "You were always the wrong guy! Until you weren't....."),
        PoetryProps("7/30/24", "A healthy relationship is one where two independent people just make a deal that they will help make the other person be the best version of themselves."),
        PoetryProps("7/30/24", "Frequently in life, I find myself making educated wishes.  Thank you Deadpool for helping me verbalize this feeling."),
        PoetryProps("7/22/24", "If you do not heal from what hurt you, you will bleed on people who did not cut you"),
        PoetryProps("7/21/24", "You are not always your own type!"),
        PoetryProps("7/20/24", "Lets go gambling! ChtChtCht Awe Dang it! ChtChtCHt Awe Dang it! ChtChtCHt Bing Bing Bing Bing Bing."),
        PoetryProps("7/20/24", "Thank you for the sunshine you add to the world :D"),
        PoetryProps("7/20/24", "You are worth finding, worth knowing, worth loving, you and all your one million layers, always hold that close ~ Danielle Doby"),
        PoetryProps("7/20/24", "[it is] always better than yesterday."),
        PoetryProps("7/18/24", "No, it's much more beautiful than that. That's a moth cocoon. It's ironic - butterflies get all the attention, but moths, they spin silk. They're stronger. They're faster. You see this little hole? This moth's just about to emerge. It's in there right now, struggling. It's digging its way through the thick hide of the cocoon. Now, I could help it - take my knife, gently widen the opening, and the moth would be free - but it would be too weak to survive. Struggle is nature's way of strengthening it. Now this is the second time you've asked me for your drugs back... ask me again, and it's yours."),
        PoetryProps("7/17/24", "I fucking told you so, NOT A DOUBT IN MY MIND.  And so the snake's identity has been revealed"),
        PoetryProps("7/16/24", "What did one plate say to the other plate? Dinner is on me"),
        PoetryProps("7/16/24", "What do you call a dog that meditates? Aware Wolf"),
        PoetryProps("7/8/24", "In order to fumble you must first have posession"),
        PoetryProps("7/8/24", "If I give you one message to hold in your hearts today, it’s this:  ...  The more people tell you it’s not possible, that it can’t be done, the more you should be absolutely determined to prove them wrong. Treat the word “impossible” as nothing more than motivation. Relish the opportunity to be an outsider. Embrace that label.   ...   because it’s the outsiders who change the world and who make a real and lasting difference. The more that a broken system tells you that you’re wrong, the more certain you should be that you must keep pushing ahead  ~ Donald J. Trump"),
        PoetryProps("7/7/24", "If I am pushing you to do better, it is because I see more in you than you do yourself.    The friends who let you sit in the comfort of your bad habits do not love you as much as the ones who encourage you to do better."),
        PoetryProps("7/5/24", "I provided the house, but you made it a home.  The house is my kingdom, the home is your domain."),
        PoetryProps("7/2/24", "Not everyone is on the same timeline, life is yours and yours alone"),
        PoetryProps("7/2/24", "Family is the most important thing to me, there is nothing more important than that"),
        PoetryProps("6/24/24", "You're killing me, Smalls! ~ Ham Porter"),
        PoetryProps("6/24/24", "The AXE Forgets But the Tree Remembers."),
        PoetryProps("6/24/24", "Worrying is like a rocking chair: It gives you something to do but gets you nowhere. ~ Van Wilder"),
        PoetryProps("6/24/24", "I came here tonight because when you realize you want to spend the rest of your life with somebody, you want the rest of your life to start as soon as possible."),
        PoetryProps("6/24/24", "A crown is merely a hat that lets the rain in.  ~ Frederick the Great"),
        PoetryProps("6/24/24", "You know why the right thing feels wrong? Because you are not used to it ~ Caitlin"),
        PoetryProps("6/24/24", "Your not a bad guy, you are just a man ~ Caitlin"),
        PoetryProps("6/24/24", "The question is, do you have it in you to make it epic? ~ Dementis"),
        PoetryProps("6/23/24", "To find out what one is fitted to do, and to secure an opportunity to do it, is the key to happiness.  ~ John Dewey"),
        PoetryProps("6/23/24", "HE commands the winds and the seas of Chaos, no one else."),
        PoetryProps("6/23/24", "Lust is a perversion of love.  The more lust you have in your heart, the less room you have for love."),
        PoetryProps("6/22/24", "This is not ego, this is not pride, I believe this is my vocation, and the answer to the question of why we are so deeply connected.  You prayed for a friend.  I am God's \"unanswered\" prayer."),
        PoetryProps("6/22/24", "I cannot vanish, you will not scare me; Try to get through it, try to push through it; You were not thinking that I will not do it; They be lovin' someone and I'm another story; Take the next ticket, get the next train; Why would I do it?; Anyone'd think that-"),
        PoetryProps("6/22/24", "You can go and live in France, but you can not be a frenchman, you can go to live in Germay or Turkey or Japan, but you can not become a german a turk or japanese.  But anyone from any corner of the earth, can come and live in America and become an American. ~ Ronald Regan"),
        PoetryProps("6/22/24", "When healing from a person, you will have thoughts like, I love them more than I loved anyone.  I never knew I could love someone so much and I will never love someone that much again.It is important to realize that your ability to love that person did not come from them, it came from within you.  You were always a lover, already someone who could love deeply.  Just because they are gone, does not mean that goes away.  They did not give you the capacity to love, they just gave you a place to express it.  Do not give someone else credit for how hard you could love, that was you and still is."),
        PoetryProps("6/22/24", "Things can be deleted, but there is always a record of it somewhere, what did that card originally say?"),
        PoetryProps("6/18/24", "-"),
        PoetryProps("6/18/24", "I am really glad we did this, I love these pancakes.  What? Tom Dont Go, you are still my best friend."),
        PoetryProps("6/18/24", "I HATE SUMMER!"),
        PoetryProps("6/16/24", "I have dreamed of you so often, you are no longer real. ~ Dean Young"),
        PoetryProps("6/16/24", "It has been very rare to have known you, very strange and wonderful.  ~ F Scott Fitzgerald"),
        PoetryProps("6/16/24", "How do I look away now that I have seen you? ~ Rachel Mennies"),
        PoetryProps("6/16/24", "Maybe in between forever, and nothing, there is a place for us."),
        PoetryProps("6/16/24", "You were such a contradiction in my life.   Nobody seemed to understand me or misunderstand me more than you.  "),
        PoetryProps("6/16/24", "I believe that there is another world waiting for us. A better world. And I will be waiting for you there.  ~ David Mitchell"),
        PoetryProps("6/16/24", "Perhaps, somewhere, some day at a less miserable time, we may see each other again.  ~ Vladimir Nabokov"),
        PoetryProps("6/16/24", "You will find me when you return, even if you are a thousand years late.  ~ Dulce Maria Loynaz"),
        PoetryProps("6/16/24", "I love you, I am waiting for you unbearably. ~ Vladimir Nabokov"),
        PoetryProps("6/16/24", "Even if I see you again, I will never see you again.  ~ Margaret Atwood"),
        PoetryProps("6/16/24", "Whatever I am you did it/ ~ F Scott Fitzgerald"),
        PoetryProps("6/16/24", "I love you and I always will and I am sorry, what a useless word. ~ Ernest Hemingway"),
        PoetryProps("6/10/24", "If you want to win the lottery, you have to make the money to buy a ticket."),
        PoetryProps("6/9/24", "It is easy to be the best when no one else is playing."),
        PoetryProps("6/8/24", "Careful when sensing the future Anakin, the fear of loss is the path to the dark side. ~ Yoda"),
        PoetryProps("6/7/24", "You are my first.  Not my first kiss, not my first relationship, not my first \"I love you\".  You are the first person who makes me feel like I am enough, and the first one to show me what \" I love you \" really means."),
        PoetryProps("6/6/24", "Thanks Chris.  I appreciate your concern, feel free to contact me anytime. ~ RM"),
        PoetryProps("6/5/24", "You could cheat on him, lie to him, betray him, deny him three times even, and he will still love you.  His love is unconditional."),
        PoetryProps("6/4/24", "If God is all powerful and all knowing, why bad things happen? Why bad things happen to good people? Read the book of Job, and you will get your answer."),
        PoetryProps("6/3/24", "If you hang around people who act like you aren't who you are, then you will forget who you are ~ ye"),
        PoetryProps("6/2/24", "Life sucks, it sucks, but every once in a while it's good and that's what you live for."),
        PoetryProps("6/1/24", "In order to move onto the next chapter of life, you must stop re-reading the old chapters."),
        PoetryProps("6/1/24", <a href="https://youtu.be/t31H-K7VCGg?si=GH9kcafqZmVRDd7z&t=458">I gave you a choice, live ... or die.</a>),
        PoetryProps("6/1/24", <a href="https://youtu.be/McyA9_gcmlA?si=w-mojlmfSmBzzlja&t=101">No, NO!</a>),
        PoetryProps("6/1/24", <a href="https://youtu.be/gs3IRbg17Y0?si=Al6XdRwF-N46XJT-&t=12">NO</a>),
        PoetryProps("6/1/24", "Ceaser weak, Koba weaker"),
        PoetryProps("5/31/24", "It may not be real, but it doesn't make the feelings of spirituality any less real. ~ Rachael"),
        PoetryProps("5/30/24", "It doesn't matter how many times you fall down in this life, it just matters if you are willing to get up again and again and again and don't stop until you win."),
        PoetryProps("5/29/24", "Those who stay, are supposed to stay, those who go are suppose to go."),
        PoetryProps("5/29/24", "They do not miss you until they are alone."),
        PoetryProps("5/29/24", "When a strong man puts pressure on a weak woman, she will run to a weak man to validate her, until his weakness abandons her."),
        PoetryProps("5/28/24", "Never trust anyone ever, because even your own shadow will leave you in the dark."),
        PoetryProps("5/28/24", "If you ever feel like God is not close to you, remember a teacher is always quiet during a test."),
        PoetryProps("5/27/24", "Close the window that hurt you, no matter how beautiful the view was."),
        PoetryProps("5/26/24", "I have so much love for her, and she does not want it, and I cannot have it because it is not mine, and I cannot give it to anyone or anything else because it is not theirs.  It is hers, all hers, and there is no where else to put it.  It is so fucking heavy, and I have no where to put it."),
        PoetryProps("5/26/24", "The day sucks, the month sucks, NO! It is the moment that sucks."),
        PoetryProps("5/25/24", "The day before we leave, always win big."),
        PoetryProps("5/25/24", "Don't let years of love be forgotten in the hatred of a minute."),
        PoetryProps("5/24/24", "It is just a date, not an arranged marriage. ~ Sherry Noles"),
        PoetryProps("5/24/24", "Your input SHOULD equal your output."),
        PoetryProps("5/23/24", "Fragrance will be lingering over your hands when you give out flowers."),
        PoetryProps("5/22/24", "Fool me once, shame on you, fool me twice, shame on me, fool me three times, fuck the peace signs."),
        PoetryProps("5/22/24", "Once you are oncall you are never off call"),
        PoetryProps("5/21/24", "Every bait and switch was a work of art."),
        PoetryProps("5/21/24", "I do not do that."),
        PoetryProps("5/19/24",  "If you find yourself falling in love because someone brings laughter into your life, what happens when their jokes no longer amuse you? If you fall in love because of someone's beauty, how will your feelings endure when the relentless march of time erodes that beauty? And if your love is anchored in the financial security someone provides, what will happen if their wealth evaporates? True love, in its purest  form, transcends all conditions and superficial reasons.  When you genuinely love someone, you stop seeking reasons and explanations for your affection. It's a love that sees beyond humor, beauty, or wealth. This kind of love is  unwavering and unconditional, not tethered to the changing tides of circumstance or appearance. At the core of true love, there lies a bond that defies rational thought: the acceptance and appreciation of one another exactly as they are,  regardless of what life may bring." ),
        PoetryProps("5/18/24", "When life gives you lemons, make an apology video."),
        PoetryProps("5/18/24", "Don't you want your first impression to be your best self?"),
        PoetryProps("5/18/24", "If the rule you followed brought you to this, what use the rule?"),
        PoetryProps("5/18/24", "You who suffers before it is necessary, suffers more than is necessary."),
        PoetryProps("5/18/24", "You cannot control the wind, but you can adjust your sails."),
        PoetryProps("5/18/24", "Some may disagree, but it is beautiful how in life there will be people, who will be complete and total strangers in the world, and for a fraction of a moment in time, become the most intimate and close people, only to become complete and total strangers again.  I think of my life in the back room on that island, listening to the birds and the rain, dreaming of going to the pool, or eating at the club house.  Later to lose that island, but to be living a life where I am your rock, supporting you like I did on that \"Saturday\".  Only to now, I go days, weeks in between each time you cross my mind. "),
        PoetryProps("5/16/24", "Lust Rushes, Love Waits."),
        PoetryProps("5/15/24", "I saw Snoqualmie Falls today."),
        PoetryProps("5/15/24", "The question of worth it is reliant on an outcome, its not the mindset to make something great.  Your work is to make the best thing you can, whatever happens after that, happens."),
        PoetryProps("5/15/24", "Before you can do something, you must first become something."),
        PoetryProps("5/14/24", "Your older self is counting on you, your younger self is believing in you."),
        PoetryProps("5/12/24", "No matter how many times you rewatch a film, it always ends the same."),
        PoetryProps("5/12/24", "One day I will become what is I want to be."),
        PoetryProps("5/11/24", "Shared joy is double joy, shared sorrow is half sorrow."),
        PoetryProps("5/11/24", "Stop looking for your soulmate, and start looking for your soul, mate."),
        PoetryProps("5/11/24", "Today is the day you reached back out, why did you? That is the question I will always ask myself."),
        PoetryProps("5/5/24", "Some may disagree, but it is beautiful how in life there will be people, who will be complete and total strangers in the world, and for a fraction of a moment in time, become the most intimate and close people, only to become complete and total strangers again.  I think of my life in the back room on that island, listening to the birds and the rain, dreaming of going to the pool, or eating at the club house.  Later to lose that island, but to be living a life where I am your rock, supporting you like I did on that \"Saturday\".  Only to now, I go days, weeks in between each time you cross my mind. "),
        PoetryProps("5/5/24", "Family is an important end goal for me.  I want a wife, I want children, I want legacy.  It is for this reason, I do all of the things that I do."),
        PoetryProps("5/5/24", "I do not hate you, I do not villanize you.  Things were never supposed to end the way that they did, and I know that we are both broken people.  Forgiveness and healing is all I wish for you.  You have a lot of trauma and scares, and all I ever wanted to be was a safe space for you."),
        PoetryProps("5/5/24", "No one else can you make you feel a certain way, unless you yourself agree to it."),
        PoetryProps("5/4/24", "I am my own worst bully"),
        PoetryProps("5/2/24", "When surrounded by darkness your only duty is to shine."),
        PoetryProps("5/1/24", "There are two types of people in this world, those who want to get laid, and liers. ~ Welden"),
        PoetryProps("4/28/24", "The mystery of life is not a problem to be solved, but a reality to be experienced."),
        PoetryProps("4/28/24", "Theres a reason acceptance is a stage of grief rather than a marker for its end."),
        PoetryProps("4/21/24", "Lover? If I describe love, it is when I am in a friendship with a woman, and I am finding gratification out of seeing her happy, at that point I think I am in love with her. ~ 50 cent"),
        PoetryProps("4/21/24", "I learned you need to destroy in order to grow. ~ Alpha Hijari"),
        PoetryProps("4/21/24", "The pain. It will leave you once it’s done teaching you. ~ Alpha Hijari"),
        PoetryProps("4/21/24", "Only a weak man can benefit from this, only a strong man can survive it. ~ Alpha Hijari"),
        PoetryProps("4/21/24", "Rock bottom teaches you things the mountain top never will."),
        PoetryProps("4/14/24", "I hate that I do not yet know you; I will struggle with the fact that the time I can spend with you is being reduced each day you are not yet in my life. ~ me"),
        PoetryProps("4/7/24", "If you want the rainbow, you have to put up the rain."),
        PoetryProps("4/7/24", "Idle hands are the devil's playground."),
        PoetryProps("4/7/24", "My trauma is not traditional trauma, having received mental or physical violence against myself, but it is the pressure to succeed, to deliver, to provide, to protect, not only for myself, but for friends and family and my partner, who ever they may be."),
        PoetryProps("4/5/24", "Rizzler. ~ Marguerite"),
        PoetryProps("4/1/24", "Today is the day we meet for the first time RCH, I am glad that our paths crossed.  You are my favorite jewel from my Jewel box."),
        PoetryProps("3/31/24", "He is Risen!"),
        PoetryProps("3/31/24", "Christ is lord."),
        PoetryProps("3/30/24", "Never turnt down, always turnt up"),
        PoetryProps("3/17/24", "Contentment is destoryed by comparison."),
        PoetryProps("3/16/24", "A man who lacks purpose distracts himself with pleasure."),
        PoetryProps("3/15/24", "To be old and wise you must first be young and stupid."),
        PoetryProps("3/15/24", "A man does not lick his plate, unless he knows he is eating alone."),
        PoetryProps("3/13/24", "I got to the top and I got bored, there was no where else to go."),
        PoetryProps("3/12/24", "The older I get, the better I was."),
        PoetryProps("3/12/24", "Never let a positive thought go unsaid."),
        PoetryProps("3/12/24", "If you ignore your problems they are not real."),
        PoetryProps("3/11/24", "Let them shenan once, they will shenanigan."),
        PoetryProps("3/10/24", "I had a friend who self-harmed as a result of Bullying.  I asked him, Who's side are you on?"),
        PoetryProps("3/10/24", "You won't find the same person twice, not even in the same person."),
        PoetryProps("3/10/24", "In life it is either Cook or be cooked."),
        PoetryProps("3/10/24", "Never give up on a goal because of the time it takes to achieve it.  Time is going to pass anyways."),
        PoetryProps("3/10/24", "You don't know what you don't know."),
        PoetryProps("3/10/24", "Work like it depends on you, pray like it depends on GOD."),
        PoetryProps("3/10/24", "Good strategy in life makes all the difference."),
        PoetryProps("3/10/24", "If it does not bring you peace, profits, or purpose, stop giving it time, energy, or attention."),
        PoetryProps("3/10/24", "You can't always be the most talented person in the room, but you can be the most competitive."),
        PoetryProps("3/10/24", "You cannot take advice only from those who succeed but also those who have failed. ~ David Buggay"),
        PoetryProps("3/5/24", "Only women childen and dogs are loved unconditionally. Men are loved on the condition that they can provide something. ~ Chris Rock"),
        PoetryProps("3/5/24", "You have to ride the waves of rage in order to rest on the beach of peace."),
        PoetryProps("3/5/24", "You can extract a bullet from a wound, but the pain will still linger."),
        PoetryProps("3/5/24", "All buildings, regardless of how they are built, fall the same."),
        PoetryProps("3/5/24", "You cannot open a door, and tell someone how to walk through it. ~ J"),
        PoetryProps("3/5/24", "If it makes sense you must do the opposite"),
        PoetryProps("2/29/24", "Red eyes are signs of high highs and low lows. ~ me"),
        PoetryProps("2/29/24", "Whatever."),
        PoetryProps("2/29/24", "Shhh.  Everyone's brain is noisy, that's what it does it makes thoughts.  The problem is that the root of suffering is following that Brain Noise and listening to that Brain Noise, and actually identifying with it as if it is who you are. ~ Chris Evans"),
        PoetryProps("2/28/24", "Just do it. Just do it.  Don't let your dreams be dreams.  Yesterday you said tomorrow, so just do it.  Make your dreams come true. Just do it. ~ Shia Leabouf"),
        PoetryProps("2/28/24", "It was never my way or the high way.  ~ me"),
        PoetryProps("2/28/24", "It is not that my way is the right way. The right way is the right way, and I do things the right way.  ~ me"),
        PoetryProps("2/25/24", "There are two secrets of life: One, do not tell anyone everything you know."),
        PoetryProps("2/25/24", "Never tell somebody everything, you may be educating an enemy."),
        PoetryProps("2/25/24", "If the truth shall kill them ... let them die."),
        PoetryProps("2/25/24", "One week they love me, the next week they hate me.  Both weeks I get paid."),
        PoetryProps("2/25/24", "Stree does not come from hard work.  Stress primarily comes from not taking action over something that you can have some control over.  Stress comes from ignoring things you should not be ignoring. ~ Jeffrey Bezos"),
        PoetryProps("2/25/24", "If you do the right things, then the money will come.  But you don't do the right things for the money, or else they aren't the right things."),
        PoetryProps("2/24/24", "A righteous man criticizes himself more than anyone else, thereby depriving the enemy of speaking against him. ~ St. Basil the Great"),
        PoetryProps("2/24/24", "The same power that rose CHRIST from the dead lives in you. ~ Romans 8:11"),
        PoetryProps("2/23/24", "A man who blames GOD for not answering his prayers immediately, needs to thank GOD for not punishing him for his sins immediately."),
        PoetryProps("2/23/24", "Friendship is a priceless gift that cannot be bought nor sold, but its value is far greater than a mountain made of gold.  For gold is cold and lifeless and cannot see nor hear, and in time of trouble, it is powerless to cheer.  Gold it has no ears to listen, no heart to understand, it cannot bring you comfort, or reach out a helping hand.  So when you ask GOD for a gift, be thankful if HE sends, not diamonds, pearls, or riches, but the love of real true friends. ~ Muhammad Ali"),
        PoetryProps("2/23/24", "In order to train as hard as possible, you must retain a clear image of your purpose. Once your goal is sharply, but realistically defined, all that remains is carrying out your plan.  Don't worry, however, about your individual potential.  Potential is only the expression of possibility.  Something that can be assessed accurately only in retrospect.  In other words, you'll never know how good you might have become, unless you try, So let's get with it. ~ Mike Mentzer"),
        PoetryProps("2/23/24", "Love never Fails, if it fails, it was never love."),
        PoetryProps("2/23/24", "Flee the evil desires of youth and pursue righteousness, faith, love and peace, along with those who call on the LORD with a pure heart.  For we do not have a high priest who is able to feel sympathy for our weaknesses, but we have one who has been tempted in every way just as we are.  Yet HE did not sin.  Let us then approach GOD's throne of grace with confidence, so that we may recieve mercy and find grace to help us in our time of need.  So if you think you are standing firm, be careful so that you don't fall. No temptation have overtaken you, except what is common to man kind, and GOD is faithful.  HE will not let you be tempted beyond what you can bare.  But when you are tempted, HE will also provide a way out, so you can endure it."),
        PoetryProps("2/22/24", "Don't waste your time chasing butterflies. Mend your garden, and the butterflies will come."),
        PoetryProps("2/22/24", "Do not pray for Easy Lives, pray to be Stronger Men ~ John F. Kennedy"),
        PoetryProps("2/22/24", "Launch Date")
    )
    return list
}